import { api_address } from "../lib/api_address";
import qs from "query-string";
import api from "../lib/api";

export const downExcel = (keyword) => {
  const link = document.createElement("a");
  link.href = `${api_address}/api/book/listToXls?${qs.stringify(
    keyword
  )}&access_token=${api.getToken()}`;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downEbookList = (title) => {
  const link = document.createElement("a");
  link.href = `${api_address}/api/cartBook/ebookRequestFile?${qs.stringify({
    title,
    access_token: api.getToken(),
  })}`;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downBookList = (title) => {
  const link = document.createElement("a");
  link.href = `${api_address}/api/cartBook/bookRequestFile?${qs.stringify({
    title,
    access_token: api.getToken(),
  })}`;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  link.remove();
};
