import axios from "axios";
import { useNavigate } from "react-router-dom";
import api from "../../lib/api";
import qs from "query-string";
import { useForm } from "react-hook-form";
import { BackButton, LinkButton } from "../../components/Button";

export default function RequestItemCreate() {
  const navigate = useNavigate();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, defaultValues, dirtyFields },
  } = useForm({
    defaultValues: {
      bookId: "",
      grade: "",
      publisher: "",
      creator: "",
      title: "",

      curriculum: "",
      permisiion: "",
      perpose: "",
      pubDate: "",
      isbn: "",
      distributor: "",
    },
  });

  const td_title = "pr-3 text-right text-sm lg:w-1/4 text-gray-700 font-bold";
  const td_body =
    "bg-white w-full border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 my-0.5";

  const onSubmit = (data) => {
    var changedData = {};
    Object.keys(dirtyFields).forEach((key) => (changedData[key] = data[key]));

    axios
      .post(`/api/requestBook/create`, qs.stringify(changedData), {
        headers: {
          Authorization: "bearer " + api.getToken(),
        },
      })
      .then((res) => {
        console.log(res);
        window.alert("등록되었습니다.");
        navigate(-1);
      })
      .catch((err) => window.alert(err.response.status + " ERROR"));
  };

  return (
    <>
      <div className="font-bold text-xl my-8">개별 도서 추가</div>
      <>
        <div className="mb-4 w-full lg:flex">
          {/* <div className="border border-gray-300 rounded-md bg-white w-[200px] h-[260px] lg:mr-4 mx-auto mb-8 flex items-center justify-center text-gray-300">
              Cover Image
            </div> */}
          <div className="w-full rounded-md bg-gray-100 p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <table className="w-full">
                <thead></thead>
                <tbody className="">
                  <tr>
                    <td className={td_title}>도서ID</td>
                    <td>
                      <input
                        className={td_body}
                        type="text"
                        {...register("bookId")}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className={td_title}>학교급</td>
                    <td>
                      <select {...register("grade")} className={td_body}>
                        <option value="초">초</option>
                        <option value="중">중</option>
                        <option value="고">고</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td className={td_title}>발행사</td>
                    <td>
                      <input
                        className={td_body}
                        type="text"
                        {...register("publisher")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={td_title}>저자</td>
                    <td>
                      <input
                        className={td_body}
                        type="text"
                        {...register("creator")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={td_title}>도서명</td>
                    <td>
                      <input
                        className={td_body}
                        type="text"
                        {...register("title")}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className={td_title}>교육과정</td>
                    <td>
                      <input
                        className={td_body}
                        type="text"
                        {...register("curriculum")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={td_title}>국검인</td>
                    <td>
                      <select {...register("permission")} className={td_body}>
                        <option value="국정">국정</option>
                        <option value="검정">검정</option>
                        <option value="인정">인정</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td className={td_title}>교과용도</td>
                    <td>
                      <input
                        className={td_body}
                        type="text"
                        {...register("purpose")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={td_title}>발행일</td>
                    <td>
                      <input
                        className={td_body}
                        type="date"
                        {...register("pubDate")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={td_title}>ISBN</td>
                    <td>
                      <input
                        className={td_body}
                        type="text"
                        {...register("isbn")}
                      />
                    </td>
                  </tr>
                  {/* <tr>
                    <td className={td_title}>PDF 요청일</td>
                    <td>
                      <input
                        className={td_body}
                        type="date"
                        {...register("ebookDate")}
                      />
                    </td>
                  </tr> */}
                  <tr>
                    <td className={td_title}>서책 구매처</td>
                    <td>
                      <input
                        className={td_body}
                        type="text"
                        {...register("distributor")}
                      />
                    </td>
                  </tr>
                  {/* <tr>
                    <td className={td_title}>서책 요청일</td>
                    <td>
                      <input
                        className={td_body}
                        type="date"
                        {...register("bookDate")}
                      />
                    </td>
                  </tr> */}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td className="flex col-span-2 w-full justify-end pt-2 space-x-2">
                      <input
                        type="submit"
                        value="등록"
                        className="cursor-pointer hover:bg-blue-500 mt-4 ml-auto bg-sky-500 block w-40 p-2 rounded-lg text-white "
                      />
                    </td>
                  </tr>
                </tfoot>
              </table>
            </form>
          </div>
        </div>

        <BackButton />
      </>
    </>
  );
}
