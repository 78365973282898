import axios from "axios";
import qs from "query-string";
import CartBookList from "./CartBookList";
import CartPDFList from "./CartPDFList";
import api from "../../lib/api";
import { useState } from "react";

export default function CartList() {
  const [selected, setSelected] = useState(true);
  const addProduceItem = () => {
    axios
      .post(`/api/cartBook/produce`, qs.stringify({}), {
        headers: {
          Authorization: "bearer " + api.getToken(),
        },
      })
      .then((res) => window.alert("요청 이력이 추가되었습니다."))
      .catch((err) => window.alert(err.response.status + " ERROR"));
  };

  const selectedCSS = `p-2 border text-sky-500 border-sky-500 w-full bg-white text-center font-bold`;
  const unselectedCSS = `p-2 border hover:bg-gray-500/20 border-gray-500 w-full bg-white text-center cursor-pointer`;
  return (
    <>
      <div className="lg:w-3/4 lg:mx-auto p-4 lg:p-0 mb-12">
        <div className="flex">
          <div className="font-bold text-2xl mt-8">구매 요청 도서 목록</div>
          <div
            onClick={() => addProduceItem()}
            className="ml-auto mt-8 cursor-pointer hover:bg-blue-500 border border-sky-500 bg-sky-500 p-2 px-4 rounded-lg text-white flex justify-center"
          >
            구매 요청 완료
          </div>
        </div>

        <div className="flex mt-8 w-full border border-gray-200 rounded-md justify-center text-center">
          <button
            className={!selected ? unselectedCSS : selectedCSS}
            onClick={() => setSelected((prev) => !prev)}
            disabled={selected}
          >
            PDF 구매 요청 도서
          </button>
          <button
            className={selected ? unselectedCSS : selectedCSS}
            onClick={() => setSelected((prev) => !prev)}
            disabled={!selected}
          >
            서책 구매 요청 도서
          </button>
        </div>
      </div>
      {selected && <CartPDFList />}
      {!selected && <CartBookList />}
    </>
  );
}
