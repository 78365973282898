import qs from "query-string";
import api from "../../lib/api";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import ListPagination from "../../components/ListPagination";
import { useLocation, useNavigate } from "react-router-dom";
import { printTime, searchWord } from "../../lib/print_text";
import ProduceSearchBar from "../../components/ProduceSearchBar";

export default function ProduceList() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [keyword, setKeyword] = useState({});
  const searchSize = useRef();
  const sortRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [`produceList`, { page, size, keyword }],
    queryFn: () =>
      axios
        .post(
          `/api/produceBook/list`,
          qs.stringify({ page, size }) + "&" + qs.stringify(keyword),

          {
            headers: {
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          // console.log(res);
          return res.data.data;
        })
        .catch(function (error) {
          if (error.response) {
            // 요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // 요청이 전송되었지만, 응답이 수신되지 않았습니다.
            // 'error.request'는 브라우저에서 XMLHtpRequest 인스턴스이고,
            // node.js에서는 http.ClientRequest 인스턴스입니다.
            console.log(error.request);
          } else {
            // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
            console.log("Error", error.message);
          }
          console.log(error.config);
        }),
  });

  const ItemList = data?.items?.map((item, index) => {
    const ebookLast = item?.requestLogs?.filter((item) => item.type === 0)[0];
    const bookLast = item?.requestLogs?.filter((item) => item.type === 1)[0];

    return (
      <tr
        key={index}
        onClick={() =>
          navigate(`/produce/item/${item.bookId}`, {
            state: { page, keyword, size, location: location.pathname },
          })
        }
        className=" text-center text-sm border-b border-gray-300 border-dashed odd:bg-gray-100 hover:bg-sky-50 cursor-pointer last-of-type:border-solid *:p-2"
      >
        <td>{item.bookId}</td>
        <td>{item.grade}</td>
        <td>{item.publisher}</td>
        <td>{item.creator}</td>
        <td>{item.title}</td>
        <td>{item.curriculum}</td>
        <td>{item.permission}</td>
        <td>{item.purpose}</td>
        <td>{item.pubDate}</td>
        <td>{item.isbn}</td>
        <td>{!!item.ebookLog && printTime(item.ebookLog.date)}</td>
        <td>{item.distributor}</td>
        <td>{!!item.bookLog && printTime(item.bookLog.date)}</td>
      </tr>
    );
  });

  useEffect(() => {
    if (!!location.state && location.state.page) {
      setPage(location.state.page);
      setSize(location.state.size);
      setKeyword(location.state.keyword);
    }
  }, []);

  return (
    <>
      <div className="lg:w-3/4 lg:mx-auto p-4 lg:p-0 mb-12">
        <div className="font-bold text-2xl my-8">구매 요청 도서 목록</div>
        <ProduceSearchBar
          setKeyword={setKeyword}
          setPage={setPage}
          state={location.state}
        />
        <div className="flex text-xs items-center mb-1">
          <div>
            <span className="font-bold">{searchWord(keyword)}</span> 검색 결과:{" "}
            {data?.totalCount} 건
          </div>
          <div className="ml-auto justify-end flex items-center space-x-2">
            {/* {data && (
              <div
                onClick={() => downExcel()}
                className="border text-green-700 border-green-700 rounded-sm px-2 cursor-pointer hover:bg-green-700/20"
              >
                엑셀 저장
              </div>
            )} */}
            {/* <div>정렬</div>
            <select
              id="sort"
              ref={sortRef}
              onChange={() => setSort(sortRef.current.value)}
              className="h-6 focus:outline-none focus:ring-2 focus:ring-blue-500 mx-1 border border-[#e5e7eb]"
            >
              <option name="sort" value={0} defaultValue={sort === 0}>
                제작일 최신순
              </option>
              <option name="sort" value={1} defaultValue={sort === 1}>
                도서 ID 순
              </option>
            </select> */}
            <div>페이지당 출력수</div>
            <select
              id="size"
              ref={searchSize}
              onChange={() => setSize(searchSize.current.value)}
              className="h-6 focus:outline-none focus:ring-2 focus:ring-blue-500 mx-1 border border-[#e5e7eb]"
            >
              <option name="size" value={10} defaultValue={size === 10}>
                10건
              </option>
              <option name="size" value={20} defaultValue={size === 20}>
                20건
              </option>
              <option name="size" value={50} defaultValue={size === 50}>
                50건
              </option>
              <option name="size" value={100} defaultValue={size === 100}>
                100건
              </option>
            </select>
          </div>
        </div>
        {isLoading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>{error.message}</div>
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="mx-auto w-full table-auto">
                <thead>
                  <tr className="bg-gray-200 text-center *:bg-gray-200 *:py-2 *:text-sm *:font-bold ">
                    <td>도서 ID</td>
                    <td>학교급</td>
                    <td>발행사</td>
                    <td>저자</td>
                    <td>도서명</td>
                    <td>교육과정</td>
                    <td>국검인</td>
                    <td>교과용도</td>

                    <td>발행일</td>
                    <td>ISBN</td>
                    <td>PDF 요청일</td>
                    <td>서책 구매처</td>
                    <td>서책 요청일</td>
                  </tr>
                </thead>
                <tbody>{ItemList}</tbody>
                <tfoot></tfoot>
              </table>
            </div>
            <ListPagination data={data} setPage={setPage} />
            {/* <div className="mb-16 text-right mt-4">
              <LinkButton text="목록 추가" address={`/request/register`} />
            </div> */}
          </>
        )}
      </div>
    </>
  );
}

// 신청 가능 도서 필드:
// id: 접근을 위한 인덱스 id
// bookId:도서아이디
// grade:학교급
// publisher:발행사
// creator:저자
// title:도서명
// curriculum:교육과정
// permission:국검인
// purpose:교과용도

// /api/requestBook/register (도서 목록 엑셀파일로 등록)
// file: 엑셀파일

// /api/requestBook/list (도서목록)
// 필드에 값을 채워 파라미터로주면 검색됨

// /api/requestBook/info/{접근id} (도서정보)

// /api/requestBook/remove/{접근id} (도서삭제)

// /api/requestBook/modify/{접근id} (도서 정보수정)
// 변경할 필드에 파라미터로 값을 채워 주면 됨

// /api/requestBook/create (도서생성)
// 파라미터로 값을 채워주면 생성됨
