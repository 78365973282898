import { useNavigate } from "react-router-dom";
import LoginButton from "./components/LoginButton";
import { useRecoilValue } from "recoil";
import { userIdAtom } from "./lib/atom";
import { Bars3Icon } from "@heroicons/react/16/solid";
import { useState } from "react";

export default function Header() {
  const navigate = useNavigate();
  const id = useRecoilValue(userIdAtom);
  const [visible, setVisible] = useState(false);
  return (
    <>
      <div className="bg-gray-100">
        <div className="sm:px-4 lg:w-3/4 md:flex md:mx-auto text-center">
          <div className="flex">
            <div
              className="sm:hidden absolute p-4"
              role="button"
              tabIndex={0}
              onClick={() => setVisible((prev) => !prev)}
            >
              <Bars3Icon className="h-6 text-gray-500"></Bars3Icon>
            </div>
            <div
              className=" hover:bg-gray-200 p-4 cursor-pointer my-auto  mx-auto sm:mx-0"
              onClick={() => navigate("/")}
            >
              📖{" "}
            </div>
          </div>
          <div className="sm:flex ml-auto" hidden={!visible}>
            <div
              className="hover:bg-gray-200 p-4 cursor-pointer sm:mr-8 "
              onClick={() => {
                navigate("/search/simple");
                setVisible((prev) => !prev);
              }}
            >
              통합 자료 검색
            </div>
            <div
              className="hover:bg-gray-200 p-4 cursor-pointer sm:mr-8 "
              onClick={() => {
                navigate("/admin/list");
                setVisible((prev) => !prev);
              }}
            >
              관리자용 목록 검색
            </div>
            <div
              className="hover:bg-gray-200 p-4 cursor-pointer sm:mr-8 "
              onClick={() => {
                navigate("/request/list");
                setVisible((prev) => !prev);
              }}
            >
              신청 가능 도서
            </div>
            <div
              className="hover:bg-gray-200 p-4 cursor-pointer sm:mr-8 "
              onClick={() => {
                navigate("/cart");
                setVisible((prev) => !prev);
              }}
            >
              구매 요청 도서
            </div>
            <div
              className="hover:bg-gray-200 p-4 cursor-pointer sm:mr-8 "
              onClick={() => {
                navigate("/produce/list");
                setVisible((prev) => !prev);
              }}
            >
              구매 요청 이력
            </div>

            {/* {id === "Shai" && (
              <>
              
                <div
                  className="hover:bg-gray-200 p-4 cursor-pointer sm:mr-8 "
                  onClick={() => navigate("/nlsearch")}
                >
                  Isbn 검색
                </div>
             
              </>
            )} */}

            <div className="">
              <LoginButton />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
