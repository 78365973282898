import { CameraIcon } from "@heroicons/react/16/solid";
import { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { userIdAtom } from "../lib/atom";
import { useRecoilValue } from "recoil";

export default function NlSearchBar({ setKeyword, setPage, state }) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    defaultValues: {
      title: "",
      creator: "",
      publisher: "",
      pubDate: "",
      isbn: "",
    },
  });

  const onSubmit = (data) => {
    console.log(dirtyFields);
    setKeyword(data);
    setPage(1);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const id = useRecoilValue(userIdAtom);

  useEffect(() => {
    if (!!state && state.isbn)
      setValue("isbn", state.isbn, { shouldDirty: true });
  }, []);

  const title = "no-wrap mr-2 text-sm w-1/4 lg:w-1/6 items-center flex";

  return (
    <>
      <div className="border border-gray-200 rounded-md w-full mb-8 bg-gray-50 ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="lg:flex">
            <div className="lg:w-1/2 ">
              {/* <div className="lg:w-1/2 lg:border-r lg:border-gray-300 lg:border-dotted "> */}
              <div className="flex p-1 pl-4 lg:pr-6 bg-slate-200">
                <div className={title}>도서명</div>
                <input
                  {...register("title")}
                  className="bg-white w-full border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                ></input>
              </div>
              <div className="flex p-1 pl-4 lg:pr-6 bg-slate-50">
                <div className={title}>저자</div>
                <input
                  {...register("creator")}
                  className="bg-white w-full border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                ></input>
              </div>
              <div className="flex p-1 pl-4 lg:pr-6 bg-slate-200">
                <div className={title}>발행사</div>
                <input
                  {...register("publisher")}
                  className="bg-white w-full border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                ></input>
              </div>
              <div className="flex p-1 pl-4 lg:pr-6 bg-slate-50">
                <div className={title}>발행연도</div>
                <input
                  {...register("pubDate")}
                  className="bg-white w-full border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                ></input>
              </div>
              <div className="flex p-1 pl-4 lg:pr-6 bg-slate-200 relative">
                <div className={title}>Isbn</div>
                <input
                  {...register("isbn")}
                  className="bg-white w-full border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                ></input>
                {id === "Shai" && (
                  <CameraIcon
                    onClick={() =>
                      navigate(`/camera`, {
                        state: { location: location.pathname },
                      })
                    }
                    className="h-9 p-1 text-gray-400 rounded-md hover:text-sky-500 absolute right-2 lg:right-6"
                  />
                )}
              </div>
            </div>
            <div className="lg:w-1/2 lg:pt-0  ">
              <div className="flex space-x-4 items-center pr-1 lg:pr-6 pb-4 ">
                {/* <button
                  className="cursor-pointer hover:bg-sky-500/20 mt-4 ml-auto border border-sky-500 block w-40 p-2 rounded-lg text-sky-500 "
                  onClick={(e) => {
                    e.preventDefault();
                    if (e.keyCode === 13) {
                      e.preventDefault();
                    }
                    reset();
                    console.log("default option!");
                  }}
                >
                  검색 초기화
                </button> */}
                <input
                  // type="reset"
                  type="button"
                  onClick={() => reset()}
                  value="검색옵션 초기화"
                  className="cursor-pointer hover:bg-sky-500/20 mt-4 ml-auto border border-sky-500 block w-40 p-2 rounded-lg text-sky-500 "
                />
                <input
                  type="submit"
                  value="검색"
                  className="cursor-pointer hover:bg-blue-500 mt-4 ml-auto border border-sky-500 bg-sky-500 block w-40 p-2 rounded-lg text-white "
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
