import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../lib/api";
import qs from "query-string";
import {
  DeleteButton,
  LinkButton,
  SubmitButton,
} from "../../components/Button";
import { printTime } from "../../lib/print_text";
import { useForm } from "react-hook-form";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import ProduceItemRequest from "./ProduceItemRequest";

export default function ProduceItemEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, defaultValues, dirtyFields },
  } = useForm({
    defaultValues: {
      bookId: undefined,
      grade: undefined,
      publisher: undefined,
      creator: undefined,
      title: undefined,
      curriculum: undefined,
      permission: undefined,
      purpose: undefined,
      type: undefined,
      pubDate: undefined,
      isbn: undefined,
      ebookFile: undefined,
      distributor: undefined,
    },
  });

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [`ProduceBookItem`, param.id],
    queryFn: () =>
      axios
        .post(
          `/api/produceBook/info/${param.id}`,
          {},
          {
            headers: {
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          setValue("bookId", res.data.data.bookId);
          setValue("grade", res.data.data.grade);
          setValue("publisher", res.data.data.publisher);
          setValue("creator", res.data.data.creator);
          setValue("title", res.data.data.title);
          setValue("curriculum", res.data.data.curriculum);
          setValue("permission", res.data.data.permission);
          setValue("purpose", res.data.data.purpose);
          setValue("type", res.data.data.type);
          setValue("pubDate", res.data.data.pubDate);
          setValue("isbn", res.data.data.isbn);
          setValue("ebookFile", res.data.data.ebookFile);
          setValue("distributor", res.data.data.distributor);
          // !!res.data.data.ebookLog &&
          //   setValue("ebookDate", printTime(res.data.data.ebookLog.date));
          // !!res.data.data.bookLog &&
          //   setValue("bookDate", printTime(res.data.data.bookLog.date));
          return res.data.data;
        }),
  });

  const td_title = "pr-3 text-right text-sm lg:w-1/4 text-gray-700 font-bold";
  const td_body =
    "bg-white w-full border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 my-0.5";

  const onSubmit = (data) => {
    var dirtyData = {};
    Object.keys(dirtyFields).forEach((key) => (dirtyData[key] = data[key]));
    console.log(dirtyData);
    axios
      .post(`/api/produceBook/modify/${param.id}`, qs.stringify(dirtyData), {
        headers: {
          Authorization: "bearer " + api.getToken(),
        },
      })
      .then((res) => {
        window.alert("수정 되었습니다.");
        navigate(-1);
      })
      .catch((err) => window.alert(err));
  };

  const handleDel = (e) => {
    e.preventDefault();
    if (
      window.confirm(`[ ${data.title} ] 의 도서 데이터를 삭제하시겠습니까?`)
    ) {
      if (
        window.confirm(
          "정말 삭제하시겠습니까? 삭제된 데이터는 복구되지 않습니다."
        )
      ) {
        axios
          .post(
            `/api/produceBook/remove/${param.id}`,
            {},
            {
              headers: {
                Authorization: "bearer " + api.getToken(),
              },
            }
          )
          .then((res) => {
            window.alert("삭제되었습니다.");
            navigate(-1);
          })
          .catch((err) => window.alert(err));
      } else {
        window.alert("삭제가 취소되었습니다.");
      }
    } else {
      window.alert("삭제가 취소되었습니다.");
    }
  };

  return (
    <>
      <div className="lg:w-3/4 lg:mx-auto p-4 lg:p-0 mb-12">
        {/* <div>구매 요청 이력</div> */}
        <div className="lg:mt-16 mt-4 text-sm mb-1">No. {param.id}</div>
        {isLoading ? (
          "Loading..."
        ) : error ? (
          error.message
        ) : (
          <>
            <div className="mb-4 w-full lg:flex">
              {/* <div className="border border-gray-300 rounded-md bg-white w-[200px] h-[260px] lg:mr-4 mx-auto mb-8 flex items-center justify-center text-gray-300">
                Cover Image
              </div> */}
              <div className="w-full rounded-md bg-gray-100 p-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <table className="w-full">
                    <thead></thead>
                    <tbody className="">
                      <tr>
                        <td className={td_title}>도서ID</td>
                        <td>
                          <input
                            className={td_body}
                            type="text"
                            {...register("bookId")}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className={td_title}>학교급</td>
                        <td>
                          <select {...register("grade")} className={td_body}>
                            <option value="초">초</option>
                            <option value="중">중</option>
                            <option value="고">고</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td className={td_title}>발행사</td>
                        <td>
                          <input
                            className={td_body}
                            type="text"
                            {...register("publisher")}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className={td_title}>저자</td>
                        <td>
                          <input
                            className={td_body}
                            type="text"
                            {...register("creator")}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className={td_title}>도서명</td>
                        <td>
                          <input
                            className={td_body}
                            type="text"
                            {...register("title")}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className={td_title}>교육과정</td>
                        <td>
                          <input
                            className={td_body}
                            type="text"
                            {...register("curriculum")}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className={td_title}>국검인</td>
                        <td>
                          <select
                            {...register("permission")}
                            className={td_body}
                          >
                            <option value="국정">국정</option>
                            <option value="검정">검정</option>
                            <option value="인정">인정</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td className={td_title}>교과용도</td>
                        <td>
                          <input
                            className={td_body}
                            type="text"
                            {...register("purpose")}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className={td_title}>제작종류</td>
                        <td>
                          <select {...register("type")} className={td_body}>
                            <option value=""></option>
                            <option value="brl">점자</option>
                            <option value="mag">확대</option>
                            <option value="spe">음성</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td className={td_title}>발행일</td>
                        <td>
                          <input
                            className={td_body}
                            type="date"
                            {...register("pubDate")}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className={td_title}>ISBN</td>
                        <td>
                          <input
                            className={td_body}
                            type="text"
                            {...register("isbn")}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className={td_title}>PDF 파일명</td>
                        <td>
                          <input
                            className={td_body}
                            type="text"
                            {...register("ebookFile")}
                          />
                        </td>
                      </tr>
                      {/* <tr>
                        <td className={td_title}>책제목</td>
                        <td>
                          <input
                            className={td_body}
                            
                            type="text"
                            {...register("bookTitle")}
                          />
                        </td>
                      </tr> */}
                      <tr>
                        <td className={td_title}>서책 구매처</td>
                        <td>
                          <input
                            className={td_body}
                            type="text"
                            {...register("distributor")}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className={td_title}>PDF 마지막 요청일</td>
                        <td>
                          <input
                            className={td_body}
                            type="date"
                            value={data?.ebookLog?.date || ""}
                            // {...register("ebookDate")}
                            disabled
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className={td_title + " pb-6"}>
                          서책 마지막 요청일
                        </td>
                        <td>
                          <input
                            className={td_body + " mb-6"}
                            type="date"
                            value={data?.bookLog?.date || ""}
                            // {...register("bookDate")}
                            disabled
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          className={
                            td_title + " border-t border-dashed border-gray-300"
                          }
                        >
                          요청 이력
                        </td>
                        <td className="pt-2 pl-3 border-t border-dashed border-gray-300">
                          <ProduceItemRequest bookId={data.bookId} />
                        </td>
                      </tr>
                      {/* <tr>
                        <td className={td_title}></td>
                        <td className="text-xs pt-2 text-right">
                          최종 갱신일: {printTime(data.updateTime, true)} <br />
                          최초 등록일: {printTime(data.createTime, true)}
                        </td>
                      </tr> */}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td className="flex col-span-2 w-full justify-end pt-2 space-x-2">
                          <SubmitButton text={`수정`} />
                          <DeleteButton text={`삭제`} onClick={handleDel} />
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </form>
              </div>
            </div>
            {!!location?.state?.location && (
              <button
                className="flex hover:bg-gray-200 p-2 text-gray-700  dark:hover:bg-gray-700 dark:hover:text-gray-200"
                onClick={() => {
                  navigate(location.state.location, {
                    state: location.state,
                  });
                }}
              >
                <ChevronLeftIcon className="mt-[0.2rem] h-4 items-center justify-center " />
                <span className="text-sm items-center justify-center font-semibold">
                  뒤로 가기
                </span>
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
}
