import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import api from "../../lib/api";
import qs from "query-string";
import { printTime } from "../../lib/print_text";

export default function ProduceItemRequest({ bookId }) {
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [`RequestHistory`, bookId],
    queryFn: () =>
      axios
        .post(`/api/produceBook/requestList/`, qs.stringify({ bookId }), {
          headers: {
            Authorization: "bearer " + api.getToken(),
          },
        })
        .then((res) => {
          // type:0 pdfFile type:1 bookFile
          return res.data.data;
        }),
  });

  const ebookList = data?.map((item, index) => {
    if (item.type === 0) {
      return (
        <>
          <li key={"e" + index}>{printTime(item.date)}</li>
        </>
      );
    }
  });
  const bookList = data?.map((item, index) => {
    if (item.type === 1) {
      return (
        <>
          <li key={index}>{printTime(item.date)}</li>
        </>
      );
    }
  });
  const title = "font-bold mb-2";

  return (
    <>
      {isLoading ? (
        "Loading..."
      ) : error ? (
        error.message
      ) : (
        <div className="lg:flex">
          <div className="lg:w-1/2 flex space-x-4">
            <div className={title}>PDF 요청일</div>
            <div key="e">
              <ol>{ebookList}</ol>
            </div>
          </div>
          <div className="lg:w-1/2 flex space-x-4">
            <div className={title}>서책 요청일</div>
            <div key="b">
              <ol>{bookList}</ol>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
