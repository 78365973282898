import qs from "query-string";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import api from "../lib/api";
import ListPagination from "../components/ListPagination";
import { useEffect, useRef, useState } from "react";
import SearchBar from "../components/SearchBar";
import { useLocation, useNavigate } from "react-router-dom";
import { api_address } from "../lib/api_address";
import { searchWord } from "../lib/print_text";
import { LinkButton } from "../components/Button";
import { PiMicrosoftExcelLogo } from "react-icons/pi";

export default function BookList() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [keyword, setKeyword] = useState({});
  const [sort, setSort] = useState(0);
  const navigate = useNavigate();
  const searchSize = useRef();
  const sortRef = useRef();
  const location = useLocation();

  useEffect(() => {
    if (!!location.state?.isbn) {
      setKeyword({ isbn: location.state.isbn });
    } else if (!!location.state?.page) {
      setPage(location.state.page);
      setSize(location.state.size);
      setKeyword(location.state.keyword);
      setSort(location.state.sort);
    }
  }, []);

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [`BookList`, { page, size, keyword, sort }],
    queryFn: () =>
      axios
        .post(
          `/api/book/list`,
          qs.stringify({ page, size }) +
            "&" +
            qs.stringify(keyword) +
            "&" +
            qs.stringify({ sort }),
          {
            headers: {
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          // console.log(res);
          return res.data.data;
        })
        .catch(function (error) {
          if (error.response) {
            // 요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // 요청이 전송되었지만, 응답이 수신되지 않았습니다.
            // 'error.request'는 브라우저에서 XMLHtpRequest 인스턴스이고,
            // node.js에서는 http.ClientRequest 인스턴스입니다.
            console.log(error.request);
          } else {
            // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
            console.log("Error", error.message);
          }
          console.log(error.config);
        }),
  });

  const bookList = data?.items?.map((item) => {
    return (
      <tr
        key={item.id}
        onClick={() =>
          navigate(`/admin/item/${item.id}`, {
            state: { page, sort, keyword, size, location: location.pathname },
          })
        }
        className=" text-center text-sm border-b border-gray-300 border-dashed odd:bg-gray-100 hover:bg-sky-50 cursor-pointer last-of-type:border-solid *:p-2"
      >
        {/* <td className="text-xs">{item.id}</td> */}
        <td>{item.prodDate}</td>
        <td>{item.bookId}</td>
        <td>{item.grade}</td>
        <td>{item.publisher}</td>
        <td>{item.creator}</td>
        <td>{item.title}</td>
        <td>{item.curriculum}</td>
        <td>{item.permission}</td>
        <td>{item.purpose}</td>
        <td>
          {(item.type === "brl" && "점자") ||
            (item.type === "spe" && "음성") ||
            (item.type === "mag" && "확대")}
        </td>
        <td className="text-xs">{item.pubDate}</td>
        <td className="text-xs">{item.isbn}</td>
      </tr>
    );
  });

  const downExcel = () => {
    // let parameter = keyword;
    // parameter[`access_token`] = api.getToken();

    // axios
    //   .get(
    //     `/api/book/listToXls`,
    //     { params: parameter },
    //     { responseType: `blob` }
    //   )
    //   .then((res) => {
    //     console.log(res);
    //     const url = window.URL.createObjectURL(
    //       new Blob([res.data], { type: res.headers["content-type"] })
    //     );
    //     const link = document.createElement("a");
    //     link.href = url;
    //     // link.setAttribute("download", "test.xlsx");
    //     document.body.appendChild(link);
    //     link.click();
    //     link.remove();
    //   })
    //   .catch((err) => console.log(err));
    //
    //
    const link = document.createElement("a");
    link.href = `${api_address}/api/book/listToXls?${qs.stringify(
      keyword
    )}&access_token=${api.getToken()}`;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <>
      <div className="lg:w-3/4 lg:mx-auto p-4 lg:p-0 mb-12">
        <div className="font-bold text-2xl my-8">목록 검색</div>
        {/* <div className="flex w-full my-8">
          <div
            onClick={() => navigate("/search/simple")}
            className="p-2 border border-sky-500 w-full bg-white text-sky-500 text-center cursor-pointer"
          >
            통합 검색
          </div>
          <div
            onClick={() => navigate("/search/detail")}
            className="p-2 border border-gray-500 w-full bg-white text-center cursor-pointer"
          >
            상세 검색
          </div>
        </div> */}
        <SearchBar
          setKeyword={setKeyword}
          setPage={setPage}
          state={location.state}
        />
        <div className="flex text-xs items-center mb-1">
          <div>
            <span className="font-bold">{searchWord(keyword)}</span> 검색 결과:{" "}
            {data?.totalCount} 건
          </div>
          <div className="ml-auto justify-end flex items-center space-x-2">
            {data && (
              <div
                onClick={() => downExcel()}
                className="flex border text-green-700 border-green-700 rounded-sm px-2 cursor-pointer hover:bg-green-700/20"
              >
                <PiMicrosoftExcelLogo className="mt-0.5 mr-0.5" />
                엑셀 저장
              </div>
            )}
            <div>정렬</div>
            <select
              id="sort"
              ref={sortRef}
              onChange={() => setSort(sortRef.current.value)}
              className="h-6 focus:outline-none focus:ring-2 focus:ring-blue-500 mx-1 border border-[#e5e7eb]"
            >
              <option name="sort" value={0} defaultValue={sort === 0}>
                제작일 최신순
              </option>
              <option name="sort" value={1} defaultValue={sort === 1}>
                도서 ID 순
              </option>
            </select>
            <div>페이지당 출력수</div>
            <select
              id="size"
              ref={searchSize}
              onChange={() => setSize(searchSize.current.value)}
              className="h-6 focus:outline-none focus:ring-2 focus:ring-blue-500 mx-1 border border-[#e5e7eb]"
            >
              <option name="size" value={10} defaultValue={size === 10}>
                10건
              </option>
              <option name="size" value={20} defaultValue={size === 20}>
                20건
              </option>
              <option name="size" value={50} defaultValue={size === 50}>
                50건
              </option>
              <option name="size" value={100} defaultValue={size === 100}>
                100건
              </option>
            </select>
          </div>
        </div>
        {isLoading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>{error.message}</div>
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="mx-auto w-full table-auto">
                <thead>
                  <tr className="bg-gray-200 text-center *:bg-gray-200 *:py-2 *:text-sm *:font-bold ">
                    {/* <td>No.</td> */}
                    <td>제작일</td>
                    <td>도서 ID</td>
                    <td>학교급</td>
                    <td>발행사</td>
                    <td>저자</td>
                    <td>도서명</td>
                    <td>교육과정</td>
                    <td>국검인</td>
                    <td>교과용도</td>
                    <td>제작종류</td>
                    <td>발행일</td>
                    <td>isbn</td>
                  </tr>
                </thead>
                <tbody>{bookList}</tbody>
                <tfoot></tfoot>
              </table>
            </div>
            <ListPagination data={data} setPage={setPage} />

            <div className="mb-16 text-right">
              <LinkButton text="신규 등록" address={`/item/create`} />
            </div>
          </>
        )}
      </div>
    </>
  );
}
