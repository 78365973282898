import { useQuery } from "@tanstack/react-query";
import qs from "query-string";
import axios from "axios";
import api from "../../lib/api";
import { useRef, useState } from "react";
import ListPagination from "../../components/ListPagination";
import { useLocation, useNavigate } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/24/outline";
import { TfiSave } from "react-icons/tfi";
import { downBookList, downEbookList } from "../../components/DownExcel";

export default function CartBookList() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const searchSize = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [`cartBookList`, { page, size }],
    queryFn: () =>
      axios
        .post(
          `/api/cartBook/list`,
          qs.stringify({ orderType: 1, page, size }),
          {
            headers: {
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          // console.log(res);
          return res.data.data;
        })
        .catch(function (error) {
          if (error.response) {
            // 요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // 요청이 전송되었지만, 응답이 수신되지 않았습니다.
            // 'error.request'는 브라우저에서 XMLHtpRequest 인스턴스이고,
            // node.js에서는 http.ClientRequest 인스턴스입니다.
            console.log(error.request);
          } else {
            // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
            console.log("Error", error.message);
          }
          console.log(error.config);
        }),
    // staleTime: 1000 * 60 * 3,
  });

  const gotoDetail = (item) =>
    navigate(`/cart/item/${item.id}`, {
      state: { page, size, location: location.pathname },
    });

  const removeItem = (id) => {
    axios
      .post(
        `/api/cartBook/remove/${id}`,
        {},
        {
          headers: {
            Authorization: "bearer " + api.getToken(),
          },
        }
      )
      .then((res) => refetch());
  };

  const modifyItem = (id, distributor) => {
    axios
      .post(
        `/api/cartBook/modify/${id}`,
        qs.stringify({
          orderType: document.getElementById(id + "TYPE").value,
          distributor: document.getElementById(id + "distributor").value,
          orderNum: document.getElementById(id + "NUM").value,
        }),
        {
          headers: {
            Authorization: "bearer " + api.getToken(),
          },
        }
      )
      .then((res) => {
        window.alert("저장되었습니다.");
        refetch();
      })
      .catch((err) => window.alert(err));
  };
  const ItemList = data?.items?.map((item, index) => {
    return (
      <>
        <tr
          key={"book" + item.bookId}
          className=" text-center text-sm border-b border-gray-300 border-dashed odd:bg-gray-100 cursor-default hover:bg-sky-50 last-of-type:border-solid *:p-2"
        >
          <td>{item.bookId}</td>
          <td>{item.grade}</td>
          <td>{item.publisher}</td>
          <td>{item.creator}</td>
          <td className="text-gray-800 font-semibold">{item.title}</td>
          <td>{item.curriculum}</td>
          <td>{item.permission}</td>
          <td>{item.purpose}</td>
          <td>
            <select
              className="border border-gray-300 rounded-md"
              id={item.id + "TYPE"}
            >
              <option value={0} selected={item.orderType === 0 ? true : false}>
                PDF
              </option>
              <option value={1} selected={item.orderType === 1 ? true : false}>
                서책
              </option>
            </select>
          </td>
          <td>
            <input
              className=" px-1 border border-gray-300 rounded-md"
              id={item.id + "distributor"}
              defaultValue={item.distributor}
            ></input>
          </td>
          <td className="">
            <input
              className="w-10 text-center border  border-gray-300 rounded-md"
              type="number"
              id={item.id + "NUM"}
              defaultValue={item.orderNum}
            ></input>
          </td>
          <td onClick={() => modifyItem(item.id)}>
            <TfiSave className="mx-auto text-gray-600 hover:text-blue-600" />
          </td>
          <td onClick={() => removeItem(item.id)}>
            <TrashIcon className="h-4 text-gray-600 hover:text-red-500 mx-auto" />
          </td>
        </tr>
      </>
    );
  });

  const setTitle = () => {
    downBookList("");
  };

  return (
    <div className="lg:w-3/4 lg:mx-auto p-4 lg:p-0 mb-12">
      {/* <div className="font-bold text-xl my-4">서책 구매 요청 도서</div> */}
      <div className="flex text-xs items-center mb-1">
        <div>
          {/* <span className="font-bold">{searchWord(keyword)}</span> 검색 결과:{" "} */}
          총 {data?.totalCount} 건
        </div>
        <div className="ml-auto justify-end flex items-center space-x-2">
          {data && (
            <div
              onClick={() => setTitle()}
              className="border text-green-700 border-green-700 rounded-sm px-2 cursor-pointer hover:bg-green-700/20"
            >
              엑셀 저장
            </div>
          )}
          {/* <div>정렬</div>
            <select
              id="sort"
              ref={sortRef}
              onChange={() => setSort(sortRef.current.value)}
              className="h-6 focus:outline-none focus:ring-2 focus:ring-blue-500 mx-1 border border-[#e5e7eb]"
            >
              <option name="sort" value={0} defaultValue={sort === 0}>
                제작일 최신순
              </option>
              <option name="sort" value={1} defaultValue={sort === 1}>
                도서 ID 순
              </option>
            </select> */}
          <div>페이지당 출력수</div>
          <select
            id="size"
            ref={searchSize}
            onChange={() => setSize(searchSize.current.value)}
            className="h-6 focus:outline-none focus:ring-2 focus:ring-blue-500 mx-1 border border-[#e5e7eb]"
          >
            <option name="size" value={10} defaultValue={size === 10}>
              10건
            </option>
            <option name="size" value={20} defaultValue={size === 20}>
              20건
            </option>
            <option name="size" value={50} defaultValue={size === 50}>
              50건
            </option>
            <option name="size" value={100} defaultValue={size === 100}>
              100건
            </option>
          </select>
        </div>
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error.message}</div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="mx-auto w-full table-auto">
              <thead>
                <tr
                  key={"header"}
                  className="bg-gray-200 text-center *:bg-gray-200 *:py-2 *:text-sm *:font-bold "
                >
                  <td>도서 ID</td>
                  <td>학교급</td>
                  <td>발행사</td>
                  <td>저자</td>
                  <td>도서명</td>
                  <td>교육과정</td>
                  <td>국검인</td>
                  <td>교과용도</td>
                  <td>주문종류</td>
                  <td>서책구매처</td>
                  <td>주문부수</td>
                  <td>변경저장</td>
                  <td>삭제</td>
                </tr>
              </thead>
              <tbody>{ItemList}</tbody>
              <tfoot></tfoot>
            </table>
          </div>
          <ListPagination data={data} setPage={setPage} />
          <div className="flex w-full mb-16 text-right  items-end  justify-end mt-4 space-x-4"></div>
        </>
      )}
    </div>
  );
}
