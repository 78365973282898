import qs from "query-string";
import api from "../../lib/api";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import ListPagination from "../../components/ListPagination";
import { useLocation, useNavigate } from "react-router-dom";
import RequestSearchBar from "../../components/RequestSearchBar";
import { LinkButton } from "../../components/Button";
import { searchWord } from "../../lib/print_text";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";

export default function RequestList() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [keyword, setKeyword] = useState({});
  const searchSize = useRef();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [`requestList`, { page, size, keyword }],
    queryFn: () =>
      axios
        .post(
          `/api/requestBook/list`,
          qs.stringify({ page, size }) + "&" + qs.stringify(keyword),

          {
            headers: {
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          // console.log(res);
          return res.data.data;
        })
        .catch(function (error) {
          if (error.response) {
            // 요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // 요청이 전송되었지만, 응답이 수신되지 않았습니다.
            // 'error.request'는 브라우저에서 XMLHtpRequest 인스턴스이고,
            // node.js에서는 http.ClientRequest 인스턴스입니다.
            console.log(error.request);
          } else {
            // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
            console.log("Error", error.message);
          }
          console.log(error.config);
          window.alert(error.response.status + " ERROR");
        }),
  });

  const gotoDetail = (item) =>
    navigate(`/request/item/${item.bookId}`, {
      state: { page, keyword, size, location: location.pathname },
    });

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(data?.items?.map((item) => item.bookId));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const addCartItem = (type) => {
    axios
      .post(
        `/api/cartBook/add`,
        qs.stringify({ bookId: isCheck, orderType: type, orderNum: 1 }),
        {
          headers: {
            Authorization: "bearer " + api.getToken(),
          },
        }
      )
      .then((res) => {
        window.alert(isCheck.length + "건의 아이템이 목록에 추가되었습니다.");
      })
      .catch((res) => console.log(res));
  };

  const ItemList = data?.items?.map((item, index) => {
    return (
      <tr
        key={index}
        className=" text-center text-sm border-b border-gray-300 border-dashed odd:bg-gray-100 hover:bg-sky-50 cursor-pointer last-of-type:border-solid *:p-2"
      >
        <td>
          <input
            type="checkbox"
            id={item.bookId}
            onChange={handleClick}
            checked={isCheck.includes(item.bookId)}
          />
        </td>

        <td onClick={() => gotoDetail(item)}>{item.bookId}</td>
        <td onClick={() => gotoDetail(item)}>{item.grade}</td>
        <td onClick={() => gotoDetail(item)}>{item.publisher}</td>
        <td onClick={() => gotoDetail(item)}>{item.creator}</td>
        <td
          className="text-gray-800 font-semibold"
          onClick={() => gotoDetail(item)}
        >
          {item.title}
        </td>
        <td onClick={() => gotoDetail(item)}>{item.curriculum}</td>
        <td onClick={() => gotoDetail(item)}>{item.permission}</td>
        <td onClick={() => gotoDetail(item)}>{item.purpose}</td>
        <td onClick={() => gotoDetail(item)}>{item.distributor}</td>
        <td onClick={() => gotoDetail(item)}>{item.produceId && "O"}</td>
      </tr>
    );
  });

  useEffect(() => {
    if (!!location.state && location.state.page) {
      setPage(location.state.page);
      setSize(location.state.size);
      setKeyword(location.state.keyword);
    }
  }, []);

  return (
    <>
      <div className="lg:w-3/4 lg:mx-auto p-4 lg:p-0 mb-12">
        <div className="font-bold text-2xl my-8">신청 가능 도서 목록 관리</div>
        <RequestSearchBar
          setKeyword={setKeyword}
          setPage={setPage}
          state={location.state}
        />
        <div className="flex text-xs items-center mb-1">
          <div>
            <span className="font-bold">{searchWord(keyword)}</span> 검색 결과:{" "}
            {data?.totalCount} 건
          </div>
          <div className="ml-auto justify-end flex items-center space-x-2">
            {/* {data && (
              <div
                onClick={() => downExcel()}
                className="border text-green-700 border-green-700 rounded-sm px-2 cursor-pointer hover:bg-green-700/20"
              >
                엑셀 저장
              </div>
            )} */}
            {/* <div>정렬</div>
            <select
              id="sort"
              ref={sortRef}
              onChange={() => setSort(sortRef.current.value)}
              className="h-6 focus:outline-none focus:ring-2 focus:ring-blue-500 mx-1 border border-[#e5e7eb]"
            >
              <option name="sort" value={0} defaultValue={sort === 0}>
                제작일 최신순
              </option>
              <option name="sort" value={1} defaultValue={sort === 1}>
                도서 ID 순
              </option>
            </select> */}
            <div>페이지당 출력수</div>
            <select
              id="size"
              ref={searchSize}
              onChange={() => setSize(searchSize.current.value)}
              className="h-6 focus:outline-none focus:ring-2 focus:ring-blue-500 mx-1 border border-[#e5e7eb]"
            >
              <option name="size" value={10} defaultValue={size === 10}>
                10건
              </option>
              <option name="size" value={20} defaultValue={size === 20}>
                20건
              </option>
              <option name="size" value={50} defaultValue={size === 50}>
                50건
              </option>
              <option name="size" value={100} defaultValue={size === 100}>
                100건
              </option>
            </select>
          </div>
        </div>
        {isLoading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>{error.message}</div>
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="mx-auto w-full table-auto">
                <thead>
                  <tr className="bg-gray-200 text-center *:bg-gray-200 *:py-2 *:text-sm *:font-bold ">
                    <td>
                      <input
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        onChange={handleSelectAll}
                        isChecked={isCheckAll}
                      />
                    </td>
                    <td>도서 ID</td>
                    <td>학교급</td>
                    <td>발행사</td>
                    <td>저자</td>
                    <td>도서명</td>
                    <td>교육과정</td>
                    <td>국검인</td>
                    <td>교과용도</td>
                    <td>서책구매처</td>
                    <td>요청이력</td>
                  </tr>
                </thead>
                <tbody>{ItemList}</tbody>
                <tfoot></tfoot>
              </table>
            </div>
            <ListPagination data={data} setPage={setPage} />
            <div className="flex w-full mb-16 text-right  items-end  justify-end mt-4 space-x-4">
              <button
                onClick={() => addCartItem(0)}
                className="flex bg-gray-500 hover:bg-indigo-500 rounded-md p-2 px-4 text-white text-sm"
              >
                <ShoppingCartIcon className="h-5 mr-2" /> PDF 요청
              </button>
              <button
                onClick={() => addCartItem(1)}
                className="flex bg-gray-500 hover:bg-indigo-500 rounded-md p-2 px-4 text-white text-sm"
              >
                <ShoppingCartIcon className="h-5 mr-2" /> 서책 요청
              </button>

              <LinkButton
                text="신청 가능 도서 추가"
                address={`/request/register`}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

// 신청 가능 도서 필드:
// id: 접근을 위한 인덱스 id
// bookId:도서아이디
// grade:학교급
// publisher:발행사
// creator:저자
// title:도서명
// curriculum:교육과정
// permission:국검인
// purpose:교과용도

// /api/requestBook/register (도서 목록 엑셀파일로 등록)
// file: 엑셀파일

// /api/requestBook/list (도서목록)
// 필드에 값을 채워 파라미터로주면 검색됨

// /api/requestBook/info/{접근id} (도서정보)

// /api/requestBook/remove/{접근id} (도서삭제)

// /api/requestBook/modify/{접근id} (도서 정보수정)
// 변경할 필드에 파라미터로 값을 채워 주면 됨

// /api/requestBook/create (도서생성)
// 파라미터로 값을 채워주면 생성됨
