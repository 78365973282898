import { useRef } from "react";
import { SubmitButton } from "../../components/Button";
import axios from "axios";
import api from "../../lib/api";
import { useNavigate } from "react-router-dom";
import RequestItemCreate from "./RequestItemCreate";

export default function RequestRegister() {
  const fileInput = useRef();
  const navigate = useNavigate();
  const doSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append(`file`, fileInput.current.files[0]);
    if (!fileInput.current.files[0])
      return window.alert("선택된 파일이 없습니다.");
    if (window.confirm("기존 목록이 제거됩니다. 진행하시겠습니까?")) {
      axios
        .post(`/api/requestBook/register`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "bearer " + api.getToken(),
          },
        })
        .then((res) => {
          window.alert("파일이 등록되었습니다.");
          navigate(-1);
        })
        .catch((res) => window.alert(res));
    } else {
      return;
    }
  };

  return (
    <>
      <div className="lg:w-3/4 lg:mx-auto p-4 lg:p-0 mb-12">
        <div className="font-bold text-2xl my-8">신청 가능 도서 목록 추가</div>
        <div className="font-bold text-xl my-8">목록 일괄 등록</div>
        <form
          onSubmit={doSubmit}
          className="w-full bg-gray-100 p-2 mx-auto flex rounded-md"
        >
          <input
            ref={fileInput}
            type="file"
            className="w-full rounded-md hover:bg-sky-100 file:hover:text-white text-sm file:rounded-l-md file:border-0 file:ring-0 file:hover:bg-blue-500 file:hover:cursor-pointer hover:cursor-pointer file:p-2 file:mr-4 file:px-4 bg-white "
          />
          <SubmitButton text={"파일 업로드"} />
        </form>
        <RequestItemCreate />
      </div>
    </>
  );
}
